<template>
  <div id="Checkeditem">
    <CSBreadcrumb/>
    <div class="result-panel">
        <CSTable>
            <template v-slot:thead>
                <tr>
                    <th>检查项</th>
                    <th>完成时间</th>
                </tr>
            </template>
            <template v-slot:tbody>
                <tr
                    v-for="item in checkItemsInfo"
                    :key="item.id"
                >
                    <td>
                        {{ item.name }}
                    </td>
                    <td  class="date-td">
                        {{ item.finishedTime || "-" }}
                    </td>
                </tr>
            </template>
        </CSTable>
    </div>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";

import {
  queryInspectLogItemLog
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
  name: "Checkeditem",
  props: {
    pointLogId: Number,
  },
  components: {
      CSTable,
    CSBreadcrumb,
  },
  data() {
    return {
      checkItemsInfo: [],
    }
  },
  created() {
    this.lookCheckItemsInfo();
  },
  methods: {
    // 查看检查项
    async lookCheckItemsInfo() {
      let res = await this.$fly
          .post(queryInspectLogItemLog,{
            pointLogId: this.pointLogId,
          })
      if (res.code != 0) {
        return;
      }
      this.checkItemsInfo = res.data;
    }
  },
}
</script>

<style lang="stylus" scoped>

</style>
